<template>
  <v-container fill-height fluid>
    <v-layout wrap>
      <v-flex>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-btn color="primary" class="float-right" top right @click="$router.go(-1)">Zurück</v-btn>
          </v-col>
          <v-col cols="12" sm="12" md="8" lg="8">
            <div class="card-title">Anlageninfomation</div>
            <v-card class="mx-auto" flat>
              <v-card-text>
                <v-container grid-list-md>
                  <v-form ref="form2">
                    <v-layout wrap>
                      <v-flex xs12 sm10 md10>
                        <v-text-field
                          v-model="anlage.name"
                          label="Anlagenbezeichnung*"
                          :rules="[rules.required]"
                          required
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm2 md2>
                        <v-text-field disabled v-model="anlage.id" label="ID"></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm6 md6>
                        <v-text-field v-model="anlage.gebaeude" label="Gebäude"></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm6 md6>
                        <v-select
                          label="Standort"
                          v-model="anlage.standort"
                          item-text="name"
                          item-value="id"
                          :rules="[rules.required]"
                          :items="standorte"
                        ></v-select>
                      </v-flex>
                      <v-flex xs12 sm6 md4>
                        <v-text-field v-model="anlage.typ" label="Typ"></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm6 md4>
                        <v-text-field v-model="anlage.seriennummer" label="Seriennummer"></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm6 md4>
                        <v-text-field v-model="anlage.baujahr" label="Baujahr"></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm12 md12>
                        <v-textarea v-model="anlage.comment" outlined label="Kommentar"></v-textarea>
                      </v-flex>
                    </v-layout>
                  </v-form>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="8">
            <div class="card-title">Bauteile</div>
            <v-data-table
              :headers="headers"
              :items="bauteile"
              hide-default-footer
              :items-per-page="-1"
              class="elevation-1"
            >
              <template v-slot:footer>
                <v-divider />
                <v-row class="pa-3">
                  <v-col cols="12" sm="12" md="12" lg="12">
                    <v-radio-group v-model="selectedGroup" row>
                      <v-radio
                        v-for="(group,index) in groups"
                        :key="index"
                        :label="group.name"
                        :value="index"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" sm="10" md="8" lg="6">
                    <v-select v-model="selectedType" item-value="name" :items="types">
                      <template slot="item" slot-scope="{ item }">{{ item.number }} {{ item.name }}</template>
                      <template
                        slot="selection"
                        slot-scope="{ item }"
                      >{{ item.number }} {{ item.name }}</template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" lg="2">
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" fab @click="openDialog()" color="primary" normal>
                          <v-icon>add</v-icon>
                        </v-btn>
                      </template>
                      <span>Neues Bauteil hinzufügen</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:item.name="{ item }">
                {{item.name}}
                <span v-if="item.typ !=''">({{item.typ}})</span>
              </template>
              <template v-slot:item.moveaction="{item}">
                <v-btn color="success" icon @click="moveUp(item)">
                  <v-icon>mdi-arrow-up</v-icon>
                </v-btn>
                <v-btn color="warning" icon @click="moveDown(item)">
                  <v-icon>mdi-arrow-down</v-icon>
                </v-btn>
              </template>
              <template v-slot:item.title="{ item }">{{item.number}} {{item.title}}</template>
              <template v-slot:item.action="{ item }">
                <div class="text-center inline-flex">
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        @click="edit(item)"
                        fab
                        small
                        color="primary"
                        class="btn-icon btn-cyan"
                      >
                        <i class="material-icons">settings</i>
                      </v-btn>
                    </template>
                    <span>Bauteil bearbeiten</span>
                  </v-tooltip>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        @click="deleteItem(item)"
                        fab
                        small
                        color="error"
                        class="btn-icon btn-cyan"
                      >
                        <i class="material-icons">delete</i>
                      </v-btn>
                    </template>
                    <span>Bauteil entfernen</span>
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="10">
            <v-btn
              @click="saveAnlage()"
              :disabled="!online"
              v-if="!offline || offline !=='o'"
              color="success"
              normal
            >Anlage speichern</v-btn>
            <!-- <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  @click="saveAnlageOffline()"
                  class="float-right"
                  color="orange"
                  normal
                  fab
                  dark
                >
                  <v-icon>mdi-content-save</v-icon>
                </v-btn>
              </template>
              <span>Anlage im Offline-Speicher speichern</span>
            </v-tooltip> -->
          </v-col>
           <v-col cols="12" sm="12" md="12" lg="12">
            <v-btn color="primary" class="float-right" top right @click="$router.go(-1)">Zurück</v-btn>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
    <v-dialog v-model="dialog" persistent max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="headline">Bauteile hinzufügen ({{selectedBauteil}})</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form">
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    :rules="[rules.required]"
                    v-model="newItem.name"
                    label="Bezeichnung*"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field v-model="newItem.typ" label="Typ" hint></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-data-table
                    :headers="headersbauteile"
                    :items="newItem.items"
                    :items-per-page="-1"
                    hide-default-footer
                  >
                    <template v-slot:item.name="{ item }">
                      {{item.name}}
                      <div v-if="item.measurement && item.measurement !== '-'">
                        <small v-if="item.measurement === '1'">Messung erforderlich (Soll/Ist-Wert)</small>
                        <small v-else>Messung erforderlich (Ist-Wert)</small>
                      </div>
                    </template>
                    <template v-slot:item.erf="{ item }">
                      <v-checkbox v-model="item.required"></v-checkbox>
                    </template>
                    <template v-slot:item.sonstiges="{ item }">
                      <v-select v-model="item.other" clearable :items="others"></v-select>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="saveElements()">Speichern</v-btn>
          <v-btn color="error" text @click="dialog = false">Abbrechen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      color: "",
      types: [],
      groups: [],
      bauteile: [],
      standorte: [],
      selectedBauteil: "",
      selectedGroup: "",
      selectedType: "",
      anlage: {},
      newItem: {
        id: "",
        name: "",
        typ: "",
        title: "",
        number: "",
        items: []
      },
      elements: [],
      others: ["Bedarf"],
      headers: [
        {
          text: "Bezeichnung/Typ",
          align: "left",
          sortable: false,
          value: "name"
        },
        {
          text: "Bauteil-Typ",
          align: "left",
          sortable: false,
          value: "title"
        },
        {
          text: "Aktion",
          sortable: false,
          value: "action"
        },
        {
          ext: "Anordnung",
          sortable: false,
          value: "moveaction"
        }
      ],
      headersbauteile: [
        {
          text: "Nummer",
          align: "left",
          sortable: false,
          value: "number"
        },
        {
          text: "Aufgabe",
          align: "left",
          sortable: false,
          value: "name"
        },
        {
          text: "Erforderlich",
          value: "erf",
          sortable: false
        },
        {
          text: "Sonstiges",
          value: "sonstiges",
          sortable: false
        }
      ],
      rules: {
        required: value => !!value || "Required."
      }
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    blockid() {
      return this.$route.params.blockid;
    },
    offline() {
      return this.$route.params.offline;
    },
    customerid() {
      return this.$route.params.customerid;
    },
    mode() {
      return this.$route.params.mode;
    },
    online() {
      return this.$store.getters.online;
    }
  },
  mounted: function() {
    this.getStructure();
    this.getStandorte();
    if (this.mode && this.mode === "edit") {
      if (this.offline && this.offline === "o") {
        const data = this.$store.getters.offlinedata;
        if (data && data.offlineanlagen) {
          const id = parseInt(this.id);
          const anlage = data.offlineanlagen.find(a => {
            return parseInt(a.id) === id;
          });
          if (anlage) {
            this.anlage = anlage;
            this.bauteile = anlage.listitems;
          }
        }
      } else {
        this.getAnlangeInfo();
      }
    }
  },
  watch: {
    selectedGroup(index) {
      this.types = this.groups[index].structure;
      this.selectedType = this.types[0].name;
    }
  },
  methods: {
    getStructure() {
      if (this.online) {
        let formData = new FormData();
        this.$http({
          method: "post",
          url: "getWartungStructure.php",
          data: formData
        })
          .then(response => {
            if (
              response.data &&
              response.data.success === false &&
              response.data.missingToken
            ) {
              let axios = this.$http;
              this.$store
                .dispatch("logout", { axios })
                .then(resp => {
                  this.$router.push("/login");
                })
                .catch(err => {
                  console.log(err);
                });
            }
            if (response.data && response.data.success && response.data.data) {
              this.groups = response.data.data;
              this.selectedGroup = 0;
            }
          })
          .catch(function(error) {
            error;
          });
      } else {
        const data = this.$store.getters.offlinedata;
        if (data && data.structure) {
          this.groups = data.structure;
          this.selectedGroup = 0;
        }
      }
    },
    getStandorte() {
      if (this.online) {
        let formData = new FormData();
        if (this.customerid) {
          formData.append("id", this.customerid);
        } else {
          formData.append("id", this.id);
        }
        this.$http({
          method: "post",
          url: "getStandorte.php",
          data: formData
        })
          .then(response => {
            if (
              response.data &&
              response.data.success === false &&
              response.data.missingToken
            ) {
              let axios = this.$http;
              this.$store
                .dispatch("logout", { axios })
                .then(resp => {
                  this.$router.push("/login");
                })
                .catch(err => {
                  console.log(err);
                });
            }
            if (
              response.data &&
              response.data.success === false &&
              response.data.notAllowed
            ) {
              this.$router.push("/");
            }
            if (response.data && response.data.success && response.data.data) {
              this.standorte = response.data.data;
            }
          })
          .catch(function(error) {
            error;
          });
      } else {
        const data = this.$store.getters.offlinedata;
        const id = this.customerid || this.id;
        if (data && data.standorte) {
          const standorte = data.standorte.filter(c => {
            return c.customerid === id;
          });
          if (standorte && standorte.length) {
            this.standorte = data.standorte;
          }
        }
      }
    },
    getAnlangeInfo() {
      let formData = new FormData();
      formData.append("id", this.id);
      this.$http({
        method: "post",
        url: "getAnlage.php",
        data: formData
      })
        .then(response => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then(resp => {
                this.$router.push("/login");
              })
              .catch(err => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.notAllowed
          ) {
            this.$router.push("/");
          }
          if (response.data && response.data.success && response.data.data) {
            this.anlage = response.data.data;
            this.bauteile = JSON.parse(this.anlage.items);
          }
        })
        .catch(function(error) {
          error;
        });
    },
    openDialog() {
      this.clearItem();
      const selectedType = this.types.find(type => {
        return type.name === this.selectedType;
      });

      if (selectedType) {
        this.selectedBauteil = selectedType.number + " " + selectedType.name;
        let items = JSON.parse(JSON.stringify(selectedType.items));
        this.newItem.group = this.groups[this.selectedGroup].name;
        this.newItem.items = items;
        this.dialog = true;
      } else {
        const msg =
          "Beim Laden ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.";
        const color = "error";
        this.$store.dispatch("snackbar", { msg, color });
      }
    },
    clearItem() {
      this.newItem = {
        id: "",
        name: "",
        typ: "",
        title: "",
        number: "",
        items: []
      };
    },
    saveAnlageOffline() {
      if (this.offline && this.offline === "o") {
        const data = this.$store.getters.offlinedata;
        if (data && data.offlineanlagen) {
          const id = parseInt(this.id);
          data.offlineanlagen.forEach(a => {
            if (a.id === id) {
              a.listitems = this.bauteile;
              a.name = this.anlage.name;
              a.standort = this.anlage.standort;
              a.typ = this.anlage.typ;
              a.gebaeude = this.anlage.gebaeude;
              a.baujahr = this.anlage.baujahr;
              a.seriennummer = this.anlage.seriennummer;
            }
          });
          this.$store.dispatch("setAnlagenOffline", data.offlineanlagen);
        }
      } else {
        let newAnlage = this.anlage;
        newAnlage.listitems = this.bauteile;
        newAnlage.mode = this.mode;
        newAnlage.id = new Date().getTime();
        if (this.mode === "new") {
          newAnlage.customerid = this.id;
          if (this.blockid) {
            newAnlage.blockid = this.blockid;
          }
        } else {
          newAnlage.id = this.id;
        }
        if (this.blockid) {
          newAnlage.blockid = this.blockid;
        }
        this.$store.dispatch("addAnlageOffline", newAnlage);
      }
      const msg =
        "Die Anlage wurde erfolgreich in den Offline-Speicher gespeichert.";
      const color = "success";
      this.$store.dispatch("snackbar", { msg, color });
    },
    saveAnlage() {
      if (this.$refs.form2.validate()) {
        let formData = new FormData();
        formData.append("name", this.anlage.name);
        formData.append("standort", this.anlage.standort);
        formData.append("typ", this.anlage.typ || "");
        formData.append("gebaeude", this.anlage.gebaeude || "");
        formData.append("baujahr", this.anlage.baujahr ? this.anlage.baujahr : 0);
        formData.append("seriennummer", this.anlage.seriennummer || "");
        formData.append("comment", this.anlage.comment || "");
        formData.append("listitems", JSON.stringify(this.bauteile));
        formData.append("mode", this.mode);
        if (this.mode === "new") {
          formData.append("customerid", this.id);
          if (this.blockid) {
            formData.append("blockid", this.blockid);
          }
        } else {
          formData.append("id", this.id);
        }
        this.$http({
          method: "post",
          url: "editAnlage.php",
          data: formData
        })
          .then(response => {
            if (response.data.success) {
              if (this.mode === "edit") {
                const msg = "Die Anlage wurde erfolgreich aktualisiert.";
                const color = "success";
                this.$store.dispatch("snackbar", { msg, color });
              } else {
                const msg = "Die Anlage wurde erfolgreich erstellt.";
                const color = "success";
                this.$store.dispatch("snackbar", { msg, color });
                this.$router.go(-1);
              }
            }
          })
          .catch(function(error) {
            const msg =
              "Beim Speichern ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          });
      }
    },
    saveElements() {
      if (this.$refs.form.validate()) {
        if (!this.newItem.number && !this.newItem.title) {
          const t = this.types.find(type => {
            return type.name === this.selectedType;
          });
          if (t) {
            this.newItem.number = t.number;
            this.newItem.title = t.name;
          }
        }
        if (!this.newItem.id) {
          this.newItem.id = new Date().getTime();
          this.bauteile.push(this.newItem);
        }
        this.dialog = false;
      }
    },
    moveUp(item) {
      let bauteile = JSON.parse(JSON.stringify(this.bauteile));
      const index = bauteile.findIndex(e => e.id === item.id);
      if(index === 0){
        return;
      }
      const tmp = bauteile[index - 1];
      bauteile[index - 1] = item;
      bauteile[index] = tmp;
      this.bauteile = bauteile;

    },
    moveDown(item) {
      let bauteile = JSON.parse(JSON.stringify(this.bauteile));
      const index = bauteile.findIndex(e => e.id === item.id);
      if(index === bauteile.length - 1){
        return;
      }
      const tmp = bauteile[index + 1];
      bauteile[index + 1] = item;
      bauteile[index] = tmp;
      this.bauteile = bauteile;
    },
    deleteItem(item) {
      const index = this.bauteile.findIndex(e => e.id === item.id);
      this.bauteile.splice(index, 1);
    },
    edit(item) {
      this.newItem = item;
      this.selectedBauteil = item.number + " " + item.title;
      this.dialog = true;
    }
  }
};
</script>
